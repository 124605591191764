<template>
  <div id="footer" class="hidden-sm-and-up">
    <router-link to="/" class="footer_item" :class="active == 0? 'active' :''">
      <div class="icon_wrap">
        <van-icon name="wap-home" class="ft_icon"/>
      </div>
      <p class="footer_info">Inicio</p>
    </router-link>
    <router-link to="/share" class="footer_item" :class="active == 1? 'active' :''">
      <div class="icon_wrap">
        <van-icon name="point-gift" class="ft_icon"/>
      </div>
      <p class="footer_info">Promoção</p>
    </router-link>
    <router-link to="/recharge" class="footer_item" :class="active == 2? 'active' :''">
      <div class="icon_wrap">
        <van-icon name="card" class="ft_icon_gem" />
      </div>
      <p class="footer_info">Fundos</p>
    </router-link>
    <router-link to="/support" class="footer_item" :class="active == 3? 'active' :''">
      <div class="icon_wrap">
        <van-icon name="service" class="ft_icon"/>
      </div>
      <p class="footer_info">Suporte</p>
    </router-link>
    <router-link to="/person" class="footer_item" :class="active == 4? 'active' :''">
      <div class="icon_wrap">
        <van-icon name="manager-o" class="ft_icon"/>
      </div>
      <p class="footer_info">Perfil</p>
    </router-link>
  </div>
</template>
<script>
export default {


  data() {
    return {
        active:0,
    };
  },
  created() {
    // console.log(this.active)
      this.$bus.$on('send2ftnav',(idx)=>{
          this.active = idx;
      })
  },

};
</script>
<style lang="less" scoped>
#footer {
  position: fixed;
  height: 1.6rem;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: rgb(5, 48, 159);
  color: #777777;
  //background-image: url("../../assets/images/common/bg.png");
  background-image: url("../../assets/images/footer/bg.png");

}

.footer_item {
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.3rem 0;
  position: relative;
}

.icon_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ft_icon {
  font-size: 0.667rem;
  color: #fff
}
.ft_icon_gem{
  font-size: 0.667rem;
  color: #fff
}

.footer_info {
  color: #fff;
  margin-top: 0.1rem;
}
.active .ft_icon{
  color: #e5c616;
}
.active .footer_info{
  color: #e5c616
}
</style>